var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relation-recommend-item"},[_c('div',{staticClass:"relation-recommend-switch"},[_c('span',{staticClass:"relation-recommend-switch-pre"},[_vm._v("是否启用关联推荐")]),_c('span',{staticClass:"relation-recommend-switch-after"},[_vm._v("(暂不支持小程序、网页版)")]),_c('el-switch',{attrs:{"size":"mini","active-color":"#366AFF","inactive-color":"#C0C4CC"},model:{value:(_vm.unit.content.recommend.status),callback:function ($$v) {_vm.$set(_vm.unit.content.recommend, "status", $$v)},expression:"unit.content.recommend.status"}})],1),(
            _vm.unit.content.recommend.status ||
            (!_vm.unit.content.recommend.status &&
                !_vm.isInitRec(_vm.unit.content.recommend))
        )?_c('div',{staticClass:"relation-recommend-content"},[_c('div',{staticClass:"relation-recommend-content-des"},[_c('div',{staticClass:"relation-recommend-content-des-title"},[_vm._v(" 关联意图推荐描述 ")]),_c('el-input',{attrs:{"size":"small","placeholder":"请输入关联意图推荐描述"},model:{value:(_vm.unit.content.recommend.description),callback:function ($$v) {_vm.$set(_vm.unit.content.recommend, "description", $$v)},expression:"unit.content.recommend.description"}})],1),(_vm.unit.content.recommend.list.length != 0)?_c('div',{staticClass:"relation-recommend-content-title"},[_c('span',{staticClass:"relation-recommend-content-title-pre"},[_vm._v("关联意图")]),_c('span',{staticClass:"relation-recommend-content-title-aft"},[_vm._v("推荐文案")])]):_vm._e(),_c('div',{staticClass:"relation-recommend-content-list"},_vm._l((_vm.unit
                    .content.recommend.list),function(recommendIntent,recommendIntentIndex){return _c('div',{key:recommendIntentIndex,staticClass:"relation-recommend-content-item"},[_c('div',{staticClass:"relation-recommend-content-item-intent"},[_c('el-cascader',{attrs:{"size":"small","show-all-levels":false,"placeholder":"请搜索或选择意图","options":_vm.intentSAkillTypeOptionsNoChildren,"filterable":""},on:{"change":function($event){return _vm.intentSelectedValue(
                                $event,
                                recommendIntentIndex
                            )}},model:{value:(recommendIntent.ext.intentValue),callback:function ($$v) {_vm.$set(recommendIntent.ext, "intentValue", $$v)},expression:"recommendIntent.ext.intentValue"}})],1),_c('div',{staticClass:"relation-recommend-content-item-text-type"},[_c('el-select',{attrs:{"size":"small","placeholder":"请选择"},on:{"change":function($event){return _vm.recommendIntentCypeChange(
                                $event,
                                recommendIntentIndex
                            )}},model:{value:(recommendIntent.type),callback:function ($$v) {_vm.$set(recommendIntent, "type", $$v)},expression:"recommendIntent.type"}},_vm._l(([
                                { value: 0, label: '自定义' },
                                { value: 1, label: '跟随意图' },
                            ]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"relation-recommend-content-item-text"},[_c('el-input',{attrs:{"size":"small","disabled":recommendIntent.type == 1,"placeholder":"请输入"},model:{value:(recommendIntent.text),callback:function ($$v) {_vm.$set(recommendIntent, "text", $$v)},expression:"recommendIntent.text"}})],1),_c('div',{staticClass:"relation-recommend-content-item-handle"},[_c('span',{staticClass:"el-icon-circle-close handle-icon handle-icon-delete",on:{"click":function($event){return _vm.deleteText(recommendIntentIndex)}}})])])}),0),(_vm.unit.content.recommend.list.length == 0)?_c('div',{staticClass:"empty-intent-r"},[_vm._v(" 暂无关联意图设置 ")]):_vm._e(),_c('span',{staticClass:"bottom-plus-btn el-icon-circle-plus handle-icon",on:{"click":_vm.addText}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }